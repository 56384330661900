import styled from 'styled-components';
import { mediaQuery } from '../../../utils/helpers';

export const WidgetWrapper = styled.div`
	min-height: ${props => props.height};
	position: relative;
	.meetings-iframe-container {
		@media screen and (max-width: 767px) {
			margin-top: 60px;
		}

		iframe {
			height: 653px !important;
		}
	}
	.profile_page_viewed {
		height: 700px;
		${mediaQuery.mobileXL`
            height: 480px;
        `}
	}
	.event_type_viewed {
		height: 700px;
		${mediaQuery.laptopML`
            height: 1400px;
        `}
		${mediaQuery.mobileXL`
            height: 1200px;
        `}
	}
	.date_and_time_selected {
		height: 1200px;
		${mediaQuery.laptopML`
            height: 1600px;
        `}
		${mediaQuery.mobileXL`
            height: 1400px;
        `}
	}
	.event_scheduled {
		height: 700px;
	}
`;

export const ArrowWrapper = styled.div`
	position: absolute;
	width: 140px;
	height: 140px;
	bottom: 200px;
	left: -75px;

	@media screen and (max-width: 1400px) {
		.arrow-vector {
			width: 110px !important;
		}
	}
	@media screen and (max-width: 1300px) {
		.arrow-vector {
			width: 90px !important;
		}
	}
	@media screen and (max-width: 1170px) {
		.arrow-vector {
			width: 70px !important;
		}
		bottom: 150px;
	}
	@media screen and (max-width: 767px) {
		top: -0px;
		left: 50%;
		transform: translate(-50%, 0%);
		.arrow-vector-down {
			width: 130px;
			height: 65px;
		}
	}
`;
