import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { BiCheck } from 'react-icons/bi';
import Layout from '../components/global/layout/Layout';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import SEO from '../components/global/seo';
import Customers from '../components/global/customers/Customers';
import StatsBox from '../components/global/stats-box/StatsBox';
import Testimonial from '../components/global/Testimonial/Testimonial';
import CTA from '../components/global/CTA/CTA';
import CTAWrapper from '../components/home-page/CtaWrapper/CTAWrapper';
import RichTextRenderer from '../components/global/RichTextRenderer';
import { useWindowSize } from '../hooks/useWindowSize';
import { checkIfProduction, setColors } from '../../utils/helpers';
import { CONSTANTS } from '../../utils/constants';
import ArrowVector from '../svg/arrow-vector.svg';
import ArrowVectorDown from '../svg/arrow-vector-down.svg';
import * as S from '../components/demo/style';
import * as formStyle from '../components/global/form-holder/styles';

const HUBSPOT_MEETING_WIDGET_HEIGHT = '658px';

const Demo = ({ data }) => {
	const {
		headingText,
		seoTitle,
		seoDescription,
		seoArticle,
		seoImage,
		customerTitle,
		customerImages,
		statsSection,
		testimonial,
		ctaTitle,
		ctaSubtitle,
		seoJsonSchema,
		headingSubtitle,
		headingSubtitleBulletPoints,
	} = data.contentfulDemo;

	useEffect(() => {
		window.addEventListener('message', messageHandler);
		return () => {
			window.removeEventListener('message', () => {});
		};
		// dependency needs to empty
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const script = document.createElement('script');
		script.src = CONSTANTS.HUBSPOT_MEETINGS_SCRIPT_LINK;
		script.async = true;
		document.body.appendChild(script);
	}, []);

	const [width] = useWindowSize();

	const emitFormFilledGAEvent = () => {
		window.dataLayer.push({
			event: 'wotnot-form-submit',
			wotnot_form_submit_eventCategory: 'Demo page',
		});
		window.dataLayer.push({
			event: 'demo-booked',
		});
	};

	const messageHandler = e => {
		if (e.data.msg === 'mouse_move') {
			return;
		}
		if (e.data.meetingBookSucceeded === true) {
			emitFormFilledGAEvent();
		}
	};

	const hubspotLink = checkIfProduction()
		? process.env.GATSBY_PROD_HUBSPOT_MEETING_EMBED
		: process.env.GATSBY_DEV_HUBSPOT_MEETING_EMBED;
	return (
		<Layout>
			<SEO
				title={seoTitle}
				description={seoDescription}
				article={seoArticle}
				image={seoImage}
				seoJsonSchema={seoJsonSchema}
			/>
			<WrapperOne>
				<formStyle.DemoWrapper>
					<div className="leftText">
						<h1 dangerouslySetInnerHTML={{ __html: headingText }}></h1>
						<RichTextRenderer content={headingSubtitle} />
						<ul className="unorderedList">
							{headingSubtitleBulletPoints.map((item, index) => (
								<li key={item.id}>
									<BiCheck />
									{item.title}
								</li>
							))}
						</ul>
					</div>
					<S.WidgetWrapper height={HUBSPOT_MEETING_WIDGET_HEIGHT}>
						<S.ArrowWrapper>
							{width && width < CONSTANTS.MOBILE_SCREEN_WIDTH ? (
								<ArrowVectorDown className="arrow-vector-down" />
							) : (
								<ArrowVector className="arrow-vector" />
							)}
						</S.ArrowWrapper>
						<div
							id="meetings-iframe-container"
							className="meetings-iframe-container"
							styles={{ height: '100%' }}
							data-src={`https://${hubspotLink}?embed=true`}
						></div>
					</S.WidgetWrapper>
				</formStyle.DemoWrapper>
			</WrapperOne>
			<Customers
				section2Images={customerImages}
				section2Title={customerTitle}
				customBgColor={setColors.white}
			/>
			<StatsBox data={statsSection.name} title={statsSection.title} />
			<Testimonial title={testimonial.title} src={testimonial.embed_url} />
			<WrapperOne>
				<CTAWrapper>
					<CTA title={ctaTitle} subtitle={ctaSubtitle} />
				</CTAWrapper>
			</WrapperOne>
		</Layout>
	);
};

export const query = graphql`
	{
		contentfulDemo {
			headingText
			headingSubtitle {
				raw
			}
			customerTitle
			customerImages {
				fluid {
					...GatsbyContentfulFluid
				}
				title
			}
			statsSection {
				name {
					title
					content
				}
				title
			}
			testimonial {
				title
				embed_url
			}
			ctaTitle
			ctaSubtitle
			seoTitle
			seoDescription
			seoArticle
			seoImage {
				title
				file {
					url
					details {
						image {
							height
							width
						}
					}
				}
			}
			seoJsonSchema {
				internal {
					content
				}
			}
			headingSubtitleBulletPoints {
				id
				title
			}
		}
	}
`;

export default Demo;
